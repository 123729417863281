import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faUser, faPhone } from "@fortawesome/free-solid-svg-icons";
import Footer from "../Footer";
import CustomBottomNavigation from "../common/BottomNavigationAction";

const ContactsList = () => {
  const [contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedMetier, setSelectedMetier] = useState("");
  const [metiers, setMetiers] = useState([]);
  const [loading, setLoading] = useState(true); // Ajout de l'état loading

  const perPage = 16;

  // Chargement initial des métiers
  useEffect(() => {
    fetchMetiers();
  }, []);

  // Fonction pour récupérer la liste des métiers
  const fetchMetiers = async () => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT_V1}/metiers`;
      const response = await axios.get(url);
      setMetiers(response.data);
    } catch (error) {
      console.error("Error fetching metiers:", error);
    }
  };

  const fetchContacts = async (page) => {
    try {
      setLoading(true); // Début du chargement

      const url = `${process.env.REACT_APP_API_ROOT_V1}/contacts?per_page=${perPage}&page=${page}&metier=${selectedMetier}`;
      const response = await axios.get(url);
      setContacts(response.data.contacts);
      setTotalPages(response.data.total_pages);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    } finally {
      setLoading(false); // Fin du chargement, que ce soit une réussite ou une erreur
    }
  };

  useEffect(() => {
    fetchContacts(currentPage);
  }, [currentPage, selectedMetier]);

  const generateWhatsAppLink = (phone) => {
    const messageText = "Bonjour, comment puis-je vous aider?";
    const encodedText = encodeURIComponent(messageText);
    return `https://wa.me/${phone}?text=${encodedText}`;
  };

  const handleMetierChange = (event) => {
    setSelectedMetier(event.target.value);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="container mx-auto my-auto mb-10 p-5 mt-20">
      <h1 className="text-2xl font-bol mb-5" style={{ fontWeight: "bolder" }}>
        Liste des Contacts
      </h1>
      <div className="">
        <label style={{ fontWeight: "bolder" }} htmlFor="metier">
          Trouver un contact:
        </label>
        &nbsp;&nbsp;
        <select
          id="metier"
          name="metier"
          value={selectedMetier}
          onChange={handleMetierChange}
          style={{
            padding: "12px",
            borderRadius: "8px",
            background: "white",
            color: "#2b2929",
            fontWeight: "bolder",
            border: "2px solid #4d4b4b",
          }}
          className="shadow-lg"
        >
          <option value="">Tous les métiers</option>
          {metiers.map((metier) => (
            <option
              key={metier}
              value={metier}
              style={{
                background: "#2b2929",
                color: "white",
              }}
            >
              {metier}
            </option>
          ))}
        </select>
      </div>

      <br />

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-4">
        {loading ? (
          <div className='col-span-3 w-4 h-4 absolute left-1/2 top-1/2 rounded-full border-4 border-b-0 border-blue-500 animate-spin'/>
        ) : contacts.length === 0 ? (
          <p>Aucun contact pour le moment</p>
        ) : (
          contacts.map((contact) => (
            <div
              key={contact.id}
              className="card shadow-lg rounded-lg p-4 bg-white"
            >
              <center>
                <div className="avatar-container mb-2">
                  {contact.photo ? (
                    <img
                      src={contact.photo.url}
                      alt={`Photo de ${contact.name}`}
                      className="avatar-icon"
                      style={{ width: 80, height: 100 }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faUser}
                      className="avatar-icon text-gray-500"
                      style={{ fontSize: 80 }}
                    />
                  )}
                </div>
              </center>
              <h2 className="text-lg font-medium mb-2">{contact.name}</h2>
              <p className="text-gray-500 text-sm mb-2">
                Téléphone: {contact.whatsapp}
              </p>
              <center>
  {/**              <img
                  src="https://www.adcocody.ci/img/logo.png"
                  alt="Logo AD-Excellence.net"
                  className="h-10 mb-2"
                /> */}
              </center>
              <center>
                <p
                  className="text-gray-500 text-sm mb-2"
                  style={{
                    background: "",
                    color: "#1c1d22",
                    border: "2px solid #1c1d22",
                    borderRadius: 10,
                    padding: 10,
                    fontWeight: "bolder",
                  }}
                >
                  {contact.metier}
                </p>
              </center>
              <center>
                <button
                  className="bg-green-500 text-white rounded mt-2"
                  onClick={() =>
                    window.open(
                      generateWhatsAppLink(contact.whatsapp),
                      "_blank"
                    )
                  }
                  style={{
                    paddingTop: 7,
                    paddingBottom: 5,
                    paddingLeft: 15,
                    paddingRight: 15,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    style={{
                      fontSize: 20,
                      fontWeight: "bolder",
                    }}
                  />
                </button>
                &nbsp;
                <button
                  className="bg-green-500 text-white rounded mt-2"
                  onClick={() =>
                    window.open(`tel:${contact.whatsapp}`, "_self")
                  }
                  style={{
                    paddingTop: 7,
                    background: "#092175",
                    paddingBottom: 5,
                    paddingLeft: 15,
                    paddingRight: 15,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{
                      fontSize: 20,
                      fontWeight: "bolder",
                    }}
                  />
                </button>
              </center>
            </div>
          ))
        )}
      </div>
      <center>
      <div className='' style={{ marginBottom: '140px' }}>
          <button
            className="btn-primary text-white bg-blue-500 rounded-lg hover:shadow-lg disabled:opacity-50"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
            style={{
              marginRight: "5px",
              padding: 8,
              fontSize: 16,
              background: "#002763",
            }}
          >
            Précédent
          </button>
          <span style={{ margin: "0 5px", fontSize: 17 }}>
            {currentPage} sur {totalPages}
          </span>
          <button
            className="btn-primary text-white bg-blue-500 rounded-lg hover:shadow-lg disabled:opacity-50"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
            style={{
              marginLeft: "5px",
              padding: 8,
              fontSize: 16,
              background: "#002763",
            }}
          >
            Suivant
          </button>
        </div>
      </center>
    </div>
    {/*<div style={{height:'150px'}}></div> */}
    {/*<Footer/>*/}
    <CustomBottomNavigation/>
    </>
  );
};

export default ContactsList;
