import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import he from 'he';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { WhatsappShareButton, FacebookShareButton } from 'react-share';
import Footer from "../../Footer";
import CustomBottomNavigation from "../../common/BottomNavigationAction";

const Single = () => {
  const { id } = useParams();
  const [post, setPost] = useState({});
  const [postDates, setPostDates] = useState({});

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleFacebookShare = () => {
    if (Object.keys(post).length > 0 && post.id) {
      const postId = post.id;
      const postUrl = `${process.env.REACT_APP_API_DOMAIN}/posts/${postId}`;

      if (isValidUrl(postUrl)) {
        // Mettez à jour les balises Open Graph avant de partager sur Facebook

        const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(postUrl)}`;
        window.open(facebookLink, '_blank');
      } else {
        console.error('Invalid URL:', postUrl);
      }
    } else {
      console.error('No valid post found for sharing on Facebook.');
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Requête pour obtenir les détails de l'article
        const postUrl = `${process.env.REACT_APP_API_ROOT}/posts/${id}`;
        const postResponse = await axios.get(postUrl);
        setPost(postResponse.data);

        // Requête pour obtenir la date de publication de l'article
        const postDateUrl = `${process.env.REACT_APP_API_ROOT}/posts/${id}?_fields=date`;
        const postDateResponse = await axios.get(postDateUrl);
        setPostDates({ ...postDates, [id]: postDateResponse.data.date });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <>
      {Object.keys(post).length ? (
        <div className="container mx-auto p-10 bg-white mt-20" style={{ marginBottom: 100 }}>
          <div className="mb-5">
            <img src={post.featured_src} alt={post.title.rendered} className="w-full h-auto rounded-lg" />
          </div>
          <div>
            <h1 className="text-3xl font-bold mb-3">{he.decode(post.title.rendered)}</h1>
            <p className="text-gray-500 text-sm" style={{ fontWeight: 'bolder', marginBottom: 10, fontSize: 16 }}>Date de publication : {new Date(postDates[id]).toLocaleDateString()}</p>
          </div>
          <div className="text-lg leading-relaxed" dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div>
          <center>
          <br/>
          <br/>

            <div> Partager &nbsp;
            <WhatsappShareButton
  url={Object.keys(post).length > 0 ? `${process.env.REACT_APP_API_DOMAIN}/posts/${post.id}` : ''}
  title={Object.keys(post).length > 0 ? he.decode(post.title.rendered) : ''}
  separator=":: "
>
  <button
    className='share-button text-white bg-green-500'
    disabled={Object.keys(post).length === 0}
    style={{ marginRight: '5px', padding: 8, fontSize: 16 }}
  >
    <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: '5px' }} className='share-icon' />
  </button>
</WhatsappShareButton>
              
              {
                /*
                &nbsp;&nbsp;
              <FacebookShareButton
                url={post.length > 0 ? `${process.env.REACT_APP_API_DOMAIN}/posts/${post.id}` : ''}
                quote={post.length > 0 ? he.decode(post.title.rendered) : ''}
                hashtag="#YourHashtag"
              >
                <button
                  className='text-white bg-blue-500 share-button'
                  disabled={Object.keys(post).length === 0}
                  style={{ fontSize: 16 }}
                  onClick={handleFacebookShare}
                >
                  <FontAwesomeIcon icon={faFacebook} style={{ marginRight: '5px' }} className='share-icon' />
                </button>
              </FacebookShareButton>
                 */
              }
            </div>
          </center>
        </div>
      ) : (
        <div className='col-span-3 w-4 h-4 absolute left-1/2 top-1/2 rounded-full border-4 border-b-0 border-blue-500 animate-spin' />
      )}
      <CustomBottomNavigation/>
      {/*<Footer/>*/}
    </>
  );
};

export default Single;
