import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FaLaptop, FaTicketAlt } from 'react-icons/fa';
import CustomBottomNavigation from '../common/BottomNavigationAction';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const SujetPriereForm = () => {
  const [sujet, setSujet] = useState('');
  const [telephone, setTelephone] = useState(''); // Nouvel état pour le numéro de téléphone
  const [submitting, setSubmitting] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!sujet.trim() || !phoneNumber.trim()) {
      alert('Les champs Téléphone et détails de votre préoccupation ne peuvent pas être vides.');
      return;
    }

    setSubmitting(true);

    try {
      // Envoyer la demande POST pour soumettre le sujet de prière
      const url = `${process.env.REACT_APP_API_ROOT}/soumettre-sujet-de-priere`;
      const response = await axios.post(url, {
        sujet: sujet.trim(),
        telephone: phoneNumber.trim(), // Envoyer le numéro de téléphone au backend
      });

      if (response.status === 200) {
        // Utiliser SweetAlert pour afficher un message après une soumission réussie
        Swal.fire({
          title: 'Ticket ouvert avec succès ! Je vous répondrai dès que possible par SMS ou Whatsapp.',
          text: 'Attendez-vous à recevoir des retours dans les plus brefs délais !',
          icon: 'success',
          iconHtml: '<i class="far fa-thumbs-up"></i>',
          customClass: {
            popup: 'text-md',
          },
        });

        // Réinitialiser le formulaire après la soumission réussie
        setSujet('');
        setPhoneNumber('');
      } else {
        // Afficher une alerte SweetAlert en cas d'erreur
        Swal.fire({
          title: 'Erreur!',
          text: 'Erreur lors de la soumission du sujet de prière.',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error('Erreur lors de la soumission du sujet de prière:', error);

      // Afficher une alerte SweetAlert en cas d'erreur
      Swal.fire({
        title: 'Erreur!',
        text: 'Erreur lors de la soumission du sujet de prière.',
        icon: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <div style={{ padding: 20, marginTop: '65px' }}>
        <div className="flex items-center justify-center">
          <div className="bg-white p-8 rounded shadow-md w-full md:w-1/2 lg:w-1/3 relative" style={{borderRadius:10}}>
          <div className="text-sm text-gray-500 mb-4 text-center">

          <h2 className="text-2xl font-bold mb-4 text-center" style={{ fontSize: 17 }}>
              {/**<FaLaptop className="inline-block mr-2" />**/}
              
              <p style={{fontSize:14, color:'black'}}>ASSISTANCE INFORMATIQUE</p>               
            </h2>
              <center>
              <img src='1206263767.jpg' style={{width:'50px', borderRadius:'100%', height:'60px', marginTop:'-17px'}}/>
              </center>
              <p style={{fontSize:14}}>ingénieur Génie informatique<br/> (Developpeur Full Stack)</p> 
            </div>
            
            <form onSubmit={handleSubmit}>

 {/* ... (votre code JSX existant) */}
 <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Numéro de téléphone WhatsApp:</label>
{/*
        <input
          type="text"
          className="w-full p-2 border rounded"
          value={telephone}
          onChange={(e) => setTelephone(e.target.value)}
          disabled={submitting}
        />
 */}
  <PhoneInput
        className="w-full p-2 border rounded"
        international
        defaultCountry="CI" // Code ISO 3166-1 alpha-2 du pays (Côte d'Ivoire ici)
        value={phoneNumber}
        onChange={setPhoneNumber}
        disabled={submitting}
      />      
      </div>
      {/* ... (votre code JSX existant) */}            
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Votre Préoccupation:</label>
                <textarea
                  className="w-full p-2 border rounded"
                  value={sujet}
                  onChange={(e) => setSujet(e.target.value)}
                  rows="4"
                  disabled={submitting}
                  placeholder='Décrivez votre problème ou exprimez 
                votre besoin en informatique (Besoin de Formation, Conseil, Maintenance, Installation de Logiciels, Conception de Site, Applications Web, Mobile...)'
                />
              </div>
              <button
                className="w-full text-white px-4 py-2 rounded hover:bg-blue-600 transition-all duration-300 relative"
                type="submit"
                disabled={submitting}
                style={{background:'#1b1b1d', width:'auto', float:'right', fontWeight:'bolder'}}
              >
                {submitting && (
                  <div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                    <div className='w-4 h-4 rounded-full border-4 border-b-0 border-blue-500 animate-spin' />
                  </div>
                )}
                {submitting ? 'Envoi en cours...' : 'Soumettre'}
              </button>
            </form>
          </div>
        </div>
      </div>
      <CustomBottomNavigation />
    </>
  );
};

export default SujetPriereForm;
