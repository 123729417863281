import React, { useEffect, useState } from 'react';
import BottomNavigation from "@material-ui/core/BottomNavigation";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import RestoreIcon from "@material-ui/icons/Restore";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { useNavigate } from "react-router-dom";
import BookIcon from '@material-ui/icons/Book';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import ContactsIcon from '@material-ui/icons/Contacts';
import EventIcon from '@material-ui/icons/Event';
import axios from 'axios';
import { FaHome, FaPrayingHands, FaShoppingBasket, FaTicketAlt, FaLifeRing, FaFolder } from 'react-icons/fa';  // Importez l'icône FaPrayingHands
import { FaBook } from 'react-icons/fa';


const CustomBottomNavigation = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [backgroundColor, setBackgroundColor] = useState('#111827'); // Couleur par défaut

  useEffect(() => {
    // Faites la requête à votre API pour récupérer la couleur de fond
    const url = `${process.env.REACT_APP_API_ROOT_V1}/background-color`;
    axios.get(url)
      .then(response => {
        // Mettez à jour la couleur de fond dans l'état
        setBackgroundColor(response.data);
      })
      .catch(error => {
        console.error("Erreur lors de la récupération de la couleur de fond :", error);
      });
  }, []); // Assurez-vous de passer une dépendance vide pour que cela se produise une seule fois au chargement

  const handleNavigation = (label) => {
    switch (label) {
      case "Accueil":
        navigate("/");
        break;
      case "En Vente":
        navigate("/articles/212");
        break;
      case "Annuaire":
        navigate("/annuaire");
        break;
      case "Calendrier":
        navigate("/calendrier");
        break;
      case "Tickets":  // Nouvelle option "Tickets"
        navigate("/");  // Ajoutez le lien vers la page "Tickets"
        break;
      case "Catégories":  // Nouvelle option "Tickets"
        navigate("/categories");  // Ajoutez le lien vers la page "Tickets"
        break;
      default:
        break;
    }
  };

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={(e, newValue) => {
        setValue(newValue);
      }}
      className="fixed bottom-0 w-full text-white p-4"
      style={{ background: backgroundColor, paddingTop: 30, paddingBottom: 30 }}
    >
{/*
      <BottomNavigationAction
        style={{ color: 'white' }}
        label="Accueil"
        icon={<FaHome />}
        onClick={() => handleNavigation("Accueil")}
      />
 */}
      <BottomNavigationAction
        style={{ color: 'white', fontSize:'23px' }}
        label="Tickets"
        icon={<FaLifeRing />} 
        onClick={() => handleNavigation("Tickets")}
      />


      <BottomNavigationAction
        style={{ color: 'white', fontSize:'23px' }}
        label="Boutique"
        icon={<FaShoppingBasket />}
        onClick={() => handleNavigation("En Vente")}
      />

    <BottomNavigationAction
        style={{ color: 'white', fontSize:'24px' }}
        label="Catégories"
        icon={<FaFolder />}
        onClick={() => handleNavigation("Catégories")}
      />

      <BottomNavigationAction
        style={{ color: 'white' }}
        label="Annuaire"
        icon={<ContactsIcon />}
        onClick={() => handleNavigation("Annuaire")}
      />


{/*
      <BottomNavigationAction
        style={{ color: 'white' }}
        label="Calendrier"
        icon={<EventIcon />}
        onClick={() => handleNavigation("Calendrier")}
      />
 */}

    </BottomNavigation>
  );
}

export default CustomBottomNavigation;
